@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.calendly-inline-widget {
  height: 700px !important;
}

.rcw-chat-container {
  /* position: absolute !important; */
}

.rcw-conversation-container {
  max-width: 370px;
}

.rcw-widget-container .rcw-conversation-container > .rcw-header {
  background-color: #0284c7;
  cursor: move;
}

.rcw-message > .rcw-response > .rcw-message-text {
  background-color: #f5f5f5;
}

.rcw-message > .rcw-client > .rcw-message-text {
  background-color: #0284c7;
  color: white;
}
.rcw-new-message {
  margin: 0 12px;
}

.rcw-conversation-container .rcw-sender {
  display: flex;
  align-items: center;
}

.rcw-widget-container > .rcw-launcher {
  background-color: #0284c7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rcw-message-text a {
  color: #0284c7;
}

.rcw-conversation-container .rcw-header .rcw-close-button {
  background-color: #0284c7;
}
.grecaptcha-badge {
  /* visibility: hidden; */
}
